<template>
  <div class="grey--text">
    <div class="cr-task-creator">
      {{ creatorName }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-task-creator {
  padding: 6px 12px;
  text-align: center;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-color: #fff;
}
</style>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    creatorName() {
      return this.task["creatorName"] || "알수없음";
    }
  }
};
</script>
